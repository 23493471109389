import React from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  hero: {
    paddingTop: theme.spacing(8),
  },
  meetingTime: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  meetingDirections: {
    fontStyle: 'italic',
  },
}));

function Meetings() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container maxWidth="md" className={classes.hero}>
        <Typography variant="h4" paragraph>
          Meetings
        </Typography>
        <Typography variant="body1" paragraph>
          Meetings are a crucial component of SA and are anonymous.
        </Typography>
        <Typography variant="body1" paragraph>
          Meetings are free and there are no dues or fees. In the spirit of our
          Seventh Tradition, we are self supporting through our own
          contributions.
        </Typography>
        <Typography variant="body1" paragraph>
          Below is an up to date list of active meetings in the Orlando area.
          All meetings are for men only and closed to those who have a problem
          with sexually compulsive thinking and behavior.
        </Typography>
        <Typography variant="body1" paragraph>
          Please contact us if you have any questions or would like more
          information about a meeting.
        </Typography>
        <Typography variant="body1">(407) 476-3238</Typography>
        <List>
          <ListItem>
            <ListItemText>
              <Typography variant="body1" className={classes.meetingTime}>
                Sunday 7:30 PM
              </Typography>
              <Typography>In-person</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1" className={classes.meetingTime}>
                Monday 7:30 PM
              </Typography>
              <Typography>Closed, mixed-gender</Typography>
              <Typography>Online</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1" className={classes.meetingTime}>
                Wednesday 6:30 AM
              </Typography>
              <Typography>Closed, men-only</Typography>
              <Typography>In-person</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1" className={classes.meetingTime}>
                Wednesday 12:00 PM
              </Typography>
              <Typography>Closed, men-only</Typography>
              <Typography>In-person</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1" className={classes.meetingTime}>
                Thursday 7:30 PM
              </Typography>
              <Typography>Closed, mixed-gender</Typography>
              <Typography>In-person</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1" className={classes.meetingTime}>
                Friday 6:30 AM
              </Typography>
              <Typography>Closed, men-only</Typography>
              <Typography>In-person</Typography>
            </ListItemText>
          </ListItem>
        </List>
        <Button
          color="primary"
          variant="contained"
          component={Link}
          href="https://docs.google.com/document/d/1hoIczEqNcHpBVgNt_jYmEL4OEXQbctz40dJcsmEF0RE/pub"
          target="_blank"
          rel="noreferrer"
        >
          Printable version
        </Button>
      </Container>
    </React.Fragment>
  );
}

export default Meetings;
