import React from 'react';
import {
  Container,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  hero: {
    paddingTop: theme.spacing(8),
  },
}));

function Resources() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container maxWidth="md" className={classes.hero}>
        <Typography variant="h4" paragraph>
          Resources
        </Typography>
        <Typography variant="h5">General Links</Typography>
        <List>
          <Link href="http://www.sa.org" target="_blank" rel="noreferrer">
            <ListItem>
              <ListItemText>Official SA Website</ListItemText>
            </ListItem>
          </Link>
          <Link
            href="http://www.sa.org/meetings_phone/"
            target="_blank"
            rel="noreferrer"
          >
            <ListItem>
              <ListItemText>SA Phone Meetings</ListItemText>
            </ListItem>
          </Link>
        </List>
      </Container>
    </React.Fragment>
  );
}

export default Resources;
