import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  hero: {
    paddingTop: theme.spacing(8),
  },
}));

function Contact() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container maxWidth="md" className={classes.hero}>
        <Typography variant="h4" paragraph>
          Contact
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions, call the SA Orlando hotline at the number
          below. If we are unable to answer, a member will return your call
          within 1 business day.
        </Typography>
        <Typography variant="h6">(407) 476-3238</Typography>
      </Container>
    </React.Fragment>
  );
}

export default Contact;
