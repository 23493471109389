import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Box,
  Link,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Drawer,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Home from './Home';
import About from './About';
import Meetings from './Meetings';
import Contact from './Contact';
import Resources from './Resources';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  footer: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  list: {
    width: 250,
  },
}));

function App() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    drawerOpen: false,
    pages: [
      { link: '/', title: 'Home' },
      { link: '/about', title: 'About' },
      { link: '/meetings', title: 'Meetings' },
      { link: '/resources', title: 'Resources' },
      { link: '/contact', title: 'Contact' },
    ],
  });

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, drawerOpen: open });
  };

  return (
    <React.Fragment>
      <Router>
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar className={classes.appBar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <Menu />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer open={state.drawerOpen} onClose={toggleDrawer(false)}>
            {
              <div
                className={clsx(classes.list)}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <List>
                  {state.pages.map(page => (
                    <Link
                      component={RouterLink}
                      to={page.link}
                      color="inherit"
                      key={page.title}
                    >
                      <ListItem button>
                        <ListItemText>{page.title}</ListItemText>
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </div>
            }
          </Drawer>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/meetings">
              <Meetings />
            </Route>
            <Route path="/resources">
              <Resources />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <Box className={classes.footer}>
            <Container maxWidth="md">
              <Grid
                container
                direction="row"
                justify="center"
                align-items="center"
                spacing={4}
              >
                <Grid item md={6}>
                  <Typography variant="h6" paragraph>
                    SA Orlando
                  </Typography>
                  <Typography variant="subtitle1" paragraph>
                    (407) 476-3238
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  {state.pages.map(page => (
                    <Typography paragraph variant="body2" key={page.title}>
                      <Link
                        component={RouterLink}
                        color="inherit"
                        to={page.link}
                      >
                        {page.title}
                      </Link>
                    </Typography>
                  ))}
                </Grid>
              </Grid>
              <Typography>Copyright © 2022 SA Orlando</Typography>
            </Container>
          </Box>
        </div>
      </Router>
    </React.Fragment>
  );
}

export default App;
