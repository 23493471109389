import React from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  hero: {
    paddingTop: theme.spacing(8),
  },
}));

function About() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container maxWidth="md" className={classes.hero}>
        <Typography variant="h4" paragraph>
          About
        </Typography>
        <Typography variant="h5" paragraph>
          Who We Are
        </Typography>
        <Typography variant="body1" paragraph>
          Sexaholics Anonymous (SA) of Orlando is a fellowship of men who share
          their experience, strength, and hope with each other that they may
          solve their common problem of sexual addiction and help others to
          recover. Our primary purpose is to stay sexually sober and help others
          to achieve sexual sobriety.
        </Typography>
        <Typography variant="body1" paragraph>
          SA members became part of the fellowship because of some form of
          sexual compulsion or addiction. For some it was an addiction to
          pornography; for others it was engaging in extra-marital affairs,
          visiting prostitutes, or frequenting strip clubs. Out "acting out"
          wreaked havoc in our lives and the lives of those we loved.
        </Typography>
        <Typography variant="body1" paragraph>
          Our common thread was our inability to stop these self-destructive
          behaviors on our own. Through SA we have found the strength to achieve
          what we call <em>sexual sobriety</em>. We have a solution that works
          for us, and would like to share it with you.
        </Typography>
        <Typography variant="h5" paragraph>
          How It Works
        </Typography>
        <Typography variant="body1" paragraph>
          SA is a 12-step program founded on the principles of Alcholics
          Anonymous (AA). We seek to conquer our self-destructive thinking and
          behavior by taking positive actions.
        </Typography>
        <List>
          <ListItem>
            <ListItemText>
              <Typography variant="body1">
                • We work the 12 Steps of SA to heal ourselves physically,
                spiritually, and emotionally.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1">
                • We become part of the anonymous fellowship of SA, in which we
                find strength and monitoring through the support and advice of
                our fellow members.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1">
                • We attend meetings so that we can learn about the SA program
                and share out experience, strength, and hope with each other.
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          SA is not a form of group therapy. SA meetings are autonomous,
          conducted by SA members using our meeting guidelines. There are no
          professional leaders at an SA meeting.
        </Typography>
        <Typography variant="body1" paragraph>
          Men who believe they have a problem with sexual compulsion or
          addiction are welcome to attend SA meetings and become a part of the
          fellowship. There are no forms, dues, or fees. The only requirement is
          a desire to stay sexually sober.
        </Typography>
        <Typography variant="h5" paragraph>
          Next Steps
        </Typography>
        <List>
          <ListItem>
            <ListItemText>
              <Typography variant="body1">
                1. Attend a meeting. The complete list of local meetings can be
                found on this website under Meetings.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1">
                2. Get a temporary sponsor. Your sponsor is someone who has
                experience in the program, achieved a level of sexual sobriety,
                and is willing to work with you in recovery. Ask any SA member
                for help in finding a sponsor.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1">
                3. Use our program literature. Our guide to sexual sobriety is
                <em>Sexaholics Anonymous</em>, commonly known as "The White
                Book". You can purchase a copy of the white book at a meeting
                from the literature chair. Other books or pamphlets are also
                available from the literature chair. The quarterly SA
                newsletter, <em>Essay</em>, is available for free online.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1">
                4. Call other members. See anyone at a meeting for a current
                list of members who are available to offer you advice and
                support.
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Container>
    </React.Fragment>
  );
}

export default About;
