import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  hero: {
    paddingTop: theme.spacing(8),
  },
  heroText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logo: {
    height: '100%',
    width: '100%',
    maxWidth: 300,
  },
  media: {
    height: 140,
  },
}));

function Home() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container maxWidth="md" className={classes.hero}>
        <Grid
          container
          direction="row"
          justify="center"
          align-items="flex-start"
          spacing={8}
        >
          <Grid item md={4}>
            <img src="logo.jpg" alt="Logo" className={classes.logo} />
          </Grid>
          <Grid item md={8} className={classes.heroText}>
            <Typography variant="h3" paragraph>
              Welcome to SA Orlando
            </Typography>
            <Typography variant="body1" paragraph>
              We're glad you're here. If you identify with us and think you may
              share our problem, we would like to share our solution with you.
            </Typography>
            <div>
              <Button
                color="primary"
                variant="contained"
                component={RouterLink}
                to="/about"
              >
                Learn More
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md" className={classes.hero}>
        <Grid
          container
          direction="row"
          justify="center"
          align-items="center"
          spacing={8}
        >
          <Grid item md={4}>
            <Card>
              <CardMedia
                className={classes.media}
                image="placeholder.png"
                title="Meetings"
              />
              <CardContent>
                <Typography variant="body2">
                  Meetings are a crucial component of SA and are anonymous.
                </Typography>
              </CardContent>
              <CardActions>
                <Button component={RouterLink} to="/meetings">
                  Attend a meeting
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <CardMedia
                className={classes.media}
                image="placeholder.png"
                title="Contact"
              />
              <CardContent>
                <Typography variant="body2">
                  If you have any questions, call the SA Orlando Hotline.
                </Typography>
              </CardContent>
              <CardActions>
                <Button component={RouterLink} to="/contact">
                  Contact us
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <CardMedia
                className={classes.media}
                image="placeholder.png"
                title="Resources"
              />
              <CardContent>
                <Typography variant="body2">
                  Review helpful resources recommended by local members.
                </Typography>
              </CardContent>
              <CardActions>
                <Button component={RouterLink} to="/resources">
                  Learn more
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md" className={classes.hero}>
        <Typography variant="h4" paragraph>
          How do we define sobriety?
        </Typography>
        <Typography variant="h5" paragraph>
          Sexaholics Anonymous Statement of Principle
        </Typography>
        <Typography variant="body1" paragraph>
          We have a solution. We don’t claim it’s for everybody, but for us, it
          works. If you identify with us and think you may share our problem,
          we’d like to share our solution with you (
          <em>Sexaholics Anonymous</em>, 2).
        </Typography>
        <Typography variant="body1" paragraph>
          In defining sobriety, we do not speak for those outside Sexaholics
          Anonymous. We can only speak for ourselves. Thus, for the married
          sexaholic, sexual sobriety means having no form of sex with self or
          with persons other than the spouse. In SA’s sobriety definition, the
          term “spouse” refers to one’s partner in a marriage between a man and
          a woman. For the unmarried sexaholic, sexual sobriety means freedom
          from sex of any kind. And for all of us, single and married alike,
          sexual sobriety also includes progressive victory over lust
          (Sexaholics Anonymous, 191-192).{' '}
          <em>Passed by the General Delegate Assembly February 2010</em>
        </Typography>
        <Typography variant="body1" paragraph>
          The only requirement for SA membership is a desire to stop lusting and
          become sexually sober according to the SA sobriety definition.
        </Typography>
        <Typography variant="body1" paragraph>
          Any two or more sexaholics gathered together for SA sobriety according
          to the SA sobriety definition may call themselves an SA group.
        </Typography>
        <Typography variant="body1" paragraph>
          Meetings that do not adhere to and follow Sexaholics Anonymous’
          sobriety statement as set forth in the foregoing Statement of
          Principle adopted by the General Delegate Assembly in 2010 are not SA
          meetings and shall not call themselves SA meetings.
          <em>
            Addendum to the Statement of Principle passed by the General
            Delegate Assembly on July 2016.
          </em>
        </Typography>
      </Container>
    </React.Fragment>
  );
}

export default Home;
